import { AccountCapabilityCanSeeAllClients, AccountCapabilityCanSeeOwnClients } from "@/types";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      firstLoad: true,
      clients: [],
      selectedFilterAction: "all",
      elementsToDisplay: [],
    };
  },
  computed: {
    ...mapGetters({
      users: "users/users",
    }),
    user() {
      return this.$store.state.global.user;
    },
    canShowFilters() {
      if (!this.user || !this.user.capabilities.length) {
        return false;
      }
      return (
        this.user.capabilities.includes(AccountCapabilityCanSeeAllClients) &&
        this.user.capabilities.includes(AccountCapabilityCanSeeOwnClients)
      );
    },
    filterActions() {
      if (!this.user || !this.user.capabilities) {
        return [];
      }
      const actions = [];
      actions.push({ name: "See all clients", value: "all" });
      actions.push({ name: "See my ( and shared) clients", value: "mine" });
      const connectedUserId = this.user && this.user.id ? this.user.id : undefined;
      this.users.forEach((user) => {
        // Exclude connected user
        if (user.id !== connectedUserId) {
          actions.push({ name: `See ${user.first_name} ${user.last_name}'s clients`, value: user.id });
        }
      });
      return actions;
    },
  },
  methods: {
    ...mapActions({
      fetchUsers: "users/fetchUsers",
    }),
    onFilterActionChange(value) {
      if (this.firstLoad) {
        return;
      }
      console.log("Changed filter to: ", value);
      this.selectedFilterAction = value;
      this.fetchClients();
    },
    fetchClients() {
      console.log("Fetch clients");
    },
  },
  created() {
    this.fetchUsers();
  },
};
