<template>
  <div class="client-list-container">
    <Header
      :details="{
        back: false,
        title: 'Customer management',
      }"
    />
    <CustomerMenuTabs />
    <div class="wrapper-content">
      <header class="header-list flex">
        <div class="flex flex-align-items-center sub-header-options">
          <Select
            :options="bulkActions"
            v-if="canDeleteThings"
            @input="onBulkActionChange"
            :value="selectedBulkAction"
            class="u-mr-15"
          ></Select>
          <Select
            v-if="canShowFilters"
            :options="filterActions"
            class="u-mr-20"
            @input="onFilterActionChange"
            :value="selectedFilterAction"
          ></Select>
          <div class="search">
            <input type="text" v-model="search" :placeholder="$t('overall.search')" />
          </div>
          <button @click="popupAddClient" class="cta align-right">
            <plusSVG />
            {{ $t("clients.create") }}
          </button>
        </div>
      </header>
      <div class="table-list">
        <ul class="labels">
          <li
            class="u-flex u-flex-align-i-center u-flex-justify-c-center table-list__line table-list__line--fixed-width"
            v-if="canDeleteThings"
          >
            <input type="checkbox" style="width: auto" :checked="bulkEditAllChecked" @input="bulkEditToggleAll" />
          </li>
          <li @click="orderListBy('id', clients)" class="table-list__line table-list__line--fixed-width">
            <button>{{ "ID" }}</button>
          </li>
          <li @click="orderListBy('first_name', clients)" class="table-list__line">
            <button>{{ "Client name" }}</button>
          </li>
          <li @click="orderListBy('last_event.title', clients)" class="table-list__line">
            <button>{{ "Next task" }}</button>
          </li>
          <li class="table-list__line" @click="orderListBy('collaborators_string', clients)">
            <button>{{ "Assigned agent" }}</button>
          </li>
          <li @click="orderListBy('source', clients)" class="table-list__line">
            <button>{{ "Source" }}</button>
          </li>
          <li @click="orderListBy('language', clients)" class="table-list__line">
            <button>{{ $t("overall.language") }}</button>
          </li>
        </ul>
        <div class="elements">
          <template v-if="!loading">
            <div class="line" v-for="c in elementsToDisplay" :key="c.token">
              <a>
                <div
                  class="table-list__line table-list__line--fixed-width u-flex u-flex-align-i-center u-flex-justify-c-center"
                  v-if="canDeleteThings"
                >
                  <input
                    type="checkbox"
                    style="width: auto;"
                    :checked="isChecked(c)"
                    @change="onCheckboxChange($event, c)"
                  />
                </div>
                <div class="table-list__line table-list__line--fixed-width">
                  <router-link :to="{ name: 'edit-client', params: { token: c.token } }">{{
                    c.id ? c.id : "-"
                  }}</router-link>
                </div>
                <div class="table-list__line">
                  <router-link :to="{ name: 'edit-client', params: { token: c.token } }">{{
                    c.first_name ? c.first_name + " " + c.last_name : "-"
                  }}</router-link>
                </div>
                <div class="table-list__line">
                  <router-link :to="{ name: 'edit-client', params: { token: c.token } }">
                    {{ c.last_event ? c.last_event.title : "/" }}
                  </router-link>
                </div>
                <div class="table-list__line">
                  <router-link :to="{ name: 'edit-client', params: { token: c.token } }">
                    {{ formatCollaboratorsStr(c.collaborators, "/") }}
                  </router-link>
                </div>
                <div class="table-list__line">
                  <router-link :to="{ name: 'edit-client', params: { token: c.token } }">{{
                    c.source ? c.source : "-"
                  }}</router-link>
                </div>
                <div class="table-list__line">
                  <router-link :to="{ name: 'edit-client', params: { token: c.token } }">{{ c.language }}</router-link>
                </div>
              </a>
            </div>
          </template>
          <span class="no-element" v-else>{{ $t("Loading Clients...") }}</span>
          <span class="no-element" v-if="clients.length < 1 && !loading">{{ $t("No Client") }}</span>
        </div>
      </div>
      <Pagination v-if="clients.length" :value="clients" @changeCurrentPage="elementsToDisplay = $event" />
    </div>
  </div>
</template>

<script>
import plusSVG from "@/assets/img/plus.svg";
import Vue from "vue";
import modalBus from "@/bus/modal-bus";
import CustomerMenuTabs from "@/pages/customers/components/CustomerMenuTabs";
import mixBulkActions from "@/mixins/bulkActions.js";
import mixFilterClientsActions from "@/mixins/filterClientsActions.js";
import mixWithCanDeleteThings from "@/mixins/withCanDeleteThings";
import mixCustomerCore from "@/mixins/customerCore";

export default Vue.extend({
  name: "ClientsList",
  components: { plusSVG, CustomerMenuTabs },
  mixins: [mixBulkActions, mixFilterClientsActions, mixWithCanDeleteThings, mixCustomerCore],
  data() {
    return {
      firstLoad: true,
      isFilters: false,
      loading: true,
      clients: [],
      elementsToDisplay: [],
      filters: {},
      search: "",
      bulkDeleteApiUrl: "api/clients",
      sort: {
        name: "",
        direction: "asc",
      },
    };
  },
  watch: {
    search() {
      clearTimeout(this.timer);
      this.timer = null;
      this.timer = setTimeout(() => {
        this.loading = true;
        let from = undefined;
        if (this.selectedFilterAction !== "all") {
          from = this.selectedFilterAction;
        }
        if (this.selectedFilterAction === "mine") {
          from = this.user.id;
        }
        let url = "/api/clients/search?search=" + this.search + "&from=" + from;
        this.$axios.get(url).then((res) => {
          this.clients = res.data;
          this.loading = false;
          this.timer = null;
          if (!this.clients.length) {
            this.elementsToDisplay = [];
          }
        });
      }, 300);
    },
  },
  computed: {},
  methods: {
    popupAddClient() {
      modalBus.$emit("change", {
        component: "AddClient",
      });
      modalBus.$emit("set-size", {
        width: "400px",
        height: "auto",
        "margin-top": "50px",
      });
      modalBus.$emit("toggle");
    },
    /**
     * @override from mixin
     * @returns {Promise<void>}
     */
    async fetchClients() {
      let from = "";
      if (this.selectedFilterAction !== "all") {
        from = this.selectedFilterAction;
      }
      if (this.selectedFilterAction === "mine") {
        from = this.user.id;
      }
      const url = `/api/clients?from=${from}`;
      this.loading = true;
      this.$axios.get(url).then((res) => {
        const clients = res.data;
        clients.map((client) => {
          client.collaborators_string = this.formatCollaboratorsStr(client.collaborators);
          return client;
        });
        this.clients = clients;
        if (!this.clients.length) {
          this.elementsToDisplay = [];
        }
        this.loading = false;
      });
    },
  },
  async created() {
    if (!this.canShowFilters) {
      this.selectedFilterAction = "mine";
    }
    const response = await this.$axios.get("/api/users/profile");
    await this.$store.dispatch("setupProfile", response.data);
    await this.fetchClients();
    this.firstLoad = false;
  },
});
</script>
