














import Vue from "vue";

export default Vue.extend({
  name: "MenuTabs",
  props: {
    links: {
      default: [],
    },
  },
  methods: {},
  data() {
    return {};
  },
  created() {},
  components: {},
});
