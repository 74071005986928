<template>
  <MenuTabs :links="links"></MenuTabs>
</template>

<script>
import Vue from "vue";
import MenuTabs from "@/components/MenuTabs";
export default Vue.extend({
  name: "CustomerMenuTabs",
  components: { MenuTabs },
  data() {
    return {
      links: [
        { name: "follow-ups", title: this.$t("overall.clients_follow_up") },
        { name: "clients", title: this.$t("overall.clients") },
      ],
    };
  },
});
</script>
