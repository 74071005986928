const BULK_ACTION_NOTHING = "nothing";
const BULK_ACTION_DELETE = "delete";

export default {
  components: {},
  data() {
    return {
      bulkEditAllChecked: false,
      selectedElementsForBulkAction: [],
      selectedBulkAction: BULK_ACTION_NOTHING,
      bulkDeleteApiUrl: "...",
    };
  },
  computed: {
    bulkActions() {
      let items = [{ name: "Realize an action", value: BULK_ACTION_NOTHING }];
      if (this.selectedElementsForBulkAction.length) {
        items.push({ name: "Delete selected elements", value: BULK_ACTION_DELETE });
      }
      return items;
    },
  },
  methods: {
    isChecked(followUp) {
      const found = this.selectedElementsForBulkAction.filter((toDisplay) => followUp.token === toDisplay.token);
      return this.bulkEditAllChecked || found.length;
    },
    bulkEditToggleAll() {
      this.bulkEditAllChecked = !this.bulkEditAllChecked;
      this.selectedElementsForBulkAction = this.bulkEditAllChecked ? [...this.elementsToDisplay] : [];
    },
    onBulkActionChangeCallback(ids){},
    async onBulkActionChange(value) {
      if (this.selectedBulkAction === value) {
        return;
      }
      this.selectedBulkAction = value;
      if (value === BULK_ACTION_DELETE) {
        const confirm = window.confirm("Are you sure you want to delete the following elements ?");
        if (confirm) {
          // Get all IDs ( token )
          const ids = this.selectedElementsForBulkAction.map((item) => item.token);
          // Delete in backend
          const response = await this.$axios.delete(this.bulkDeleteApiUrl, { data: { ids } });
          if (response.status === 200) {
            // Delete in frontend
            this.elementsToDisplay = this.elementsToDisplay.filter((toDisplay) => !ids.includes(toDisplay.token));
            this.onBulkActionChangeCallback(ids);
            console.log("[Bulk edit] delete - success", response);
          } else {
            console.log("[Bulk edit] delete - error", response);
          }
          // Trick for <Select> component to trigger change
          const timer = setTimeout(() => {
            this.selectedBulkAction = BULK_ACTION_NOTHING;
            clearTimeout(timer);
          }, 50);
          this.selectedElementsForBulkAction = [];
        } else {
          console.log("[Bulk edit] delete - user refused");
          const timer = setTimeout(() => {
            this.selectedBulkAction = BULK_ACTION_NOTHING;
            clearTimeout(timer);
          }, 50);
        }
        this.bulkEditAllChecked = false;
      }
    },
    onCheckboxChange(event, element) {
      const foundIndex = this.selectedElementsForBulkAction.findIndex((item) => item.token === element.token);
      if (foundIndex >= 0) {
        this.selectedElementsForBulkAction.splice(foundIndex, 1);
      } else {
        this.selectedElementsForBulkAction.push(element);
      }
    },
  },
  created() {
  },
};
